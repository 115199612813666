import Vue from 'vue'
import PlaylistTransform from '@/plugins/player/playlist'
import { transformConfiguration } from '@/plugins/player/configuration'
import { LIVE, VOD_SERIAL } from '@/assets/javascript/enums/product-types'
import { TRAILER } from '@/assets/javascript/enums/video-types'
import { PRODUCT_TYPE_TO_VIDEO_TYPE } from '@/assets/javascript/maps/product-type-to-video-type'
import { Listeners } from '@/plugins/player/listeners'
import { TIME_SHIFT_DISABLED } from 'atds-player-layout/src/enums'
import { loadExternalFile } from '@/assets/javascript/helpers/load-external-script'
import {
  createTrackingWatches,
  createTrackingListeners
} from '@/plugins/tracking/scripts/player'

import { CorePlayer as Player, DashRenderer, NativeRenderer, HlsJSRenderer } from 'redgalaxy-player-web'
import SubtitleModule from 'redgalaxy-player-web/dist/module/subtitle'
import AdvertModule from './modules/advert'
import DrmModule from 'redgalaxy-player-web/dist/module/drm'

Player.renderers = [DashRenderer, NativeRenderer, HlsJSRenderer]
Player.modules = [
  SubtitleModule,
  AdvertModule,
  DrmModule
]

export default ({ store, app }) => {
  const player = {
    instances: [],
    async play ({ product, holderId, playerStore, mute }) {
      await this.destroy(holderId)
      const state = playerStore.state
      createPlayerInstance(holderId, playerStore)
      const productId = product.id
      const { type } = product
      const videoType = PRODUCT_TYPE_TO_VIDEO_TYPE[type]
      const id = productId
      playerStore.setProduct(product)
      if (type === LIVE && playerStore.setCurrentLiveProgram) {
        const epgItem = await store.dispatch('epg/GET_LIVE_NOW', { id: product.id, since: app.$time() })
        playerStore.setCurrentLiveProgram(epgItem)
        playerStore.setDuration(app.$time(state.currentLiveProgram.till).diff(state.currentLiveProgram.since, 's'))
      }

      return getConfiguration(id, videoType)
        .then(config => setMute(config, mute))
        .then(config => continueWatching(config, type))
        .then(config => getPlaylist(config, videoType, id))
        .then(config => setCredentials(config))
        .then(config => addAdSessionIdToSources(config))
        .then(config => {
          const haveAdvertPacket = app.$time().subtract(1, 'minute').valueOf() < store.getters['user/AD_BREAK_FREE_TILL']

          if (type === TRAILER) {
            return config
          }
          if (!haveAdvertPacket) {
            return loadExternalFile('//imasdk.googleapis.com/js/sdkloader/ima3.js')
              .then(() => {
                return getAdvertVMAP(config, type, id)
              })
              .catch((e) => {
                store.commit('adblock/SET_ADBLOCK_DETECTED', true)
              })
          }
          return config
        })
        .then(config => ({ ...config, poster: product.videoPoster }))
        .then(config => this.setup(config, holderId, videoType))
        .catch((e) => {
          store.commit('player/SET_ERROR', e)
          this.destroy(holderId)
        })
    },
    setup (config, holderId, videoType) {
      return new Promise((resolve) => {
        setTimeout(() => {
          const instance = this.getPlayerInstance(holderId)
          if (instance) {
            const playerStore = player.store

            const isResumed = !!config.startTime
            const params = { holderId, isResumed }
            const playerInstance = this.getPlayer(holderId)
            playerInstance.listeners = new Listeners(playerInstance, store.getters, store.commit, store.dispatch, app.$time, app.$tracking, config)

            if (videoType !== TRAILER) {
              playerInstance.trackingListeners = new (createTrackingListeners(params))(playerInstance.instance, app.$tracking)
              playerInstance.trackingWatches = new Vue(createTrackingWatches(params))
              if (playerStore.state.product.type === LIVE && !config.timeShift && playerStore.setTimeshiftMode) {
                playerInstance.store.setTimeshiftMode(TIME_SHIFT_DISABLED)
              }
            }
          }
          instance.setup(config)
          resolve(config)
        }, 1)
      })
    },
    async destroy (holderId) {
      const player = this.getPlayer(holderId)
      const {
        instance,
        listeners,
        trackingListeners,
        trackingWatches
      } = player || {}

      if (player) {
        if (listeners) {
          await listeners.destroy()
        }
        if (trackingListeners) {
          trackingListeners.destroy()
          this.trackingListeners = null
        }
        if (trackingWatches) {
          trackingWatches.$destroy()
          this.trackingWatches = null
        }
        if (instance) {
          instance.destroy()
        }
        this.instances = this.instances
          .filter(player => {
            return player.holder !== holderId
          })
      }
    },
    getPlayer (holderId) {
      return player.instances.find(player => player.holder === holderId)
    },
    getPlayerInstance (holderId) {
      return this.getPlayer(holderId)?.instance
    },
    getPlayerStore (holderId) {
      return this.getPlayer(holderId)?.store
    }
  }

  function createPlayerInstance (holderId, playerStore) {
    const constructor = Player
    player.instances.push({
      holder: holderId,
      instance: new constructor(holderId),
      store: playerStore
    })
    player.store = playerStore // TODO !!! TO MUSI STĄD ZNIKNĄC !! ( to jest źle dlatego że player to globalna instancja )
    if (playerStore.setSettings) {
      playerStore.setSettings()
    }
  }

  function getConfiguration (productId, videoType) {
    const state = player.store.state
    if (videoType === TRAILER) {
      return Promise.resolve(transformConfiguration({}, { videoType }, state))
    }
    return store.dispatch('player/GET', { productId, videoType })
      .then(data => transformConfiguration(data, { videoType }, state))
      .then(config => {
        store.commit('adverts/SET_AD_SESSION_ID', config.adSessionId)
        return config
      })
  }

  function setCredentials (config) {
    if (!process.env.USE_PROXY) {
      config.httpConfig = {
        drm: {
          withCredentials: true
        }
      }
    }

    return config
  }

  function getPlaylist (config, videoType, productId) {
    return store.dispatch('player/GET_PLAYLIST', {
      videoType,
      productId,
      videoSession: config.videoSession
    })
      .then(PlaylistTransform)
      .then(files => ({
        ...config,
        file: files.sources,
        drmSystems: ['widevine', 'fairplay'],
        drm: files.drm,
        subtitles: files.subtitles
      }))
  }

  function addAdSessionIdToSources (config) {
    const adSessionId = store.state.adverts.adSessionId
    if (!adSessionId) {
      return config
    }
    const file = Object.entries(config.file)
      .reduce((obj, entry) => {
        const url = entry[1].split('?')
        const search = new URLSearchParams(url[1])
        search.append('adSessionId', adSessionId)
        obj[entry[0]] = url[0] + '?' + search.toString()

        return obj
      }, {})
    return {
      ...config,
      file
    }
  }

  function setMute (config, mute) {
    if (mute) {
      config.volume = 0
    }

    return config
  }

  async function continueWatching (config, productType) {
    const product = productType === VOD_SERIAL
      ? await store.dispatch('products/GET_VOD_EPISODE', config.videoSession.productId)
      : player.store.state.product
    return new Promise((resolve) => {
      let progress = product.progress
      let item = sessionStorage.getItem('progress')

      if (item) {
        if (productType !== LIVE) {
          item = JSON.parse(item)

          if (item.productId && item.productId === product.id && item.progress) {
            progress = item.progress
          }
        }
        sessionStorage.removeItem('progress')
      }

      if (progress / product.duration < 0.9) {
        resolve({ ...config, startTime: progress })
      } else {
        resolve(config)
      }
    })
  }

  async function getAdvertVMAP (config, productType, productId) {
    if (productType === LIVE) {
      return config
    }
    const vmapUrl = await store.dispatch('adverts/GET_NORMAL_VMAP_URL', productId)

    return {
      ...config,
      vmapUrl
    }
  }

  app.$player = store.$player = Vue.prototype.$player = player
}
